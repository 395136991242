import { Business, RefEvent } from "../framework/infra"
import { round, moment } from '../framework/utils/helper'
import { Period } from '../framework/utils'

import { Earning, RemittanceMessage, RemittanceMessages } from '../entities'
import EmploymentBusiness from "./EmploymentBusiness"
import ParticipationBusiness from "./ParticipationBusiness"

export default class RemittanceDetailBusiness extends Business {
    static messages = RemittanceMessage.messages

    static calculate(detail) {
        this.validate(detail)
        if (!detail.onError()) {
            this.calculateContribs(detail)
            this.validateMaxReached(detail)
        }
    }

    static validate(detail) {
        detail.messages = new RemittanceMessages()
        this.validateParticipation(detail)
        if (!detail.onError()) this.validateVoluntaryContribs(detail)
        if (!detail.onError()) this.validateOnLeave(detail)
        if (!detail.onError()) this.validateActiveInactive(detail)
        if (!detail.onError()) this.validateMaxReached(detail)
        if (!detail.onError()) this.validateEarning(detail)
        this.validateMidMonth(detail)
    
        EmploymentBusiness.validate(detail.employment)
        ParticipationBusiness.validate(detail.employment.participation, detail.employment.participation?.isLastEmployment?.(detail.employment))
    }
    static validateParticipation(detail) {
        if (!detail.participation) {
            console.log('participation not found!!!')
            console.log(detail)
            return
        }
        if (!detail.ppStatus.isActive()) return detail.messages.add('nonEnroll') //Info Do not calculate any contributions if is not a member EXIT
        if (detail.employmentStatus.isTerminated()) detail.messages.add('terminated')  //Info if employee was terminated, we still calculate contributions
    }
    static validateMaxReached(detail) {
        if (detail.ytdEarnings.pensionable >= detail.rates.ympe) detail.messages.add('ympeReached')
        if (detail.ytdContributions.total >= detail.rates.maxContributions) detail.messages.add('maxContributionsReached')
        if (detail.earnings.regularHours > detail.workSchedule.wklySch * 5) detail.messages.add('exceedRegularHours') 
    }
    static validateVoluntaryContribs(detail) {
        const maxVoluntary = detail.isCQ === 'y' ? detail.rates.maxVoluntaryCQPP : detail.rates.maxVoluntaryNonCQPP
        if (detail.ytdContributions.vol > maxVoluntary) return detail.messages.add('maxVoluntaryExceeded', [{key: 'max', value: maxVoluntary}])
        if (detail.ytdContributions.vol === maxVoluntary) detail.messages.add('maxVoluntaryReached', [{key: 'max', value: maxVoluntary}])
    }
    static validateOnLeave(detail) {
        const periodStss = detail.employmentEventStatuses
        periodStss.forEach((eventStatus, index) => {
            const sts = eventStatus.status
            if (detail.employment.onContributingLeave(detail.period.timestampAtPeriodEnd) && !detail.employment.baseEarnings) {
                const baseEarningsMessage = new RemittanceMessage({code: 'noBaseEarnings'});
                baseEarningsMessage.severity = detail.period.yearEnd ? 'e' : 'w';
                if(!detail.messages.find(msg => msg.code === 'noBaseEarnings')) detail.messages.push(baseEarningsMessage);
            }
            if (sts.isMaternity()) {
                var matEndMoment = eventStatus.effMoment.add(detail.rates.maternityDuration - 1, 'd')
                var stsEndMoment = moment(eventStatus.endTs)
                var endMoment = matEndMoment.isBefore(stsEndMoment) ? matEndMoment : stsEndMoment
                detail.messages.add('maternityEndReminder', [{key: 'endDate', value: matEndMoment.format('YYYY MMM DD')}]) 
                if (endMoment.format('YYYY-MM') === detail.period.format('YYYY-MM') && periodStss.length === 1) return detail.messages.add('maternityEnded', [{key: 'endDate', value: endMoment.format('YYYY MMM DD')}])
                if (detail.earnings.deductable && !detail.cmt && !periodStss.find(s => s.status.isActive())) detail.messages.add('earningsOnMaternity')
            } else if (sts.isLtd()) {
                if (detail.earnings.deductable && !detail.cmt && !periodStss.find(es => es.status.isActive())){ 
                    detail.messages.add('earningsOnDisability')}
            } else if (sts.isProgressiveReturn() && (detail.earnings.deductable === 0 || detail.earnings.deductableHours === 0)) {
                detail.messages.add('progressiveNoEarnings');
            } else if (sts.isOnLeave()) {
                if (detail.earnings.deductable && !detail.cmt && !periodStss.find(es => es.status.isActive()) && !detail.employment.events.find(ev => ev.config.selfContribAccepted)) detail.messages.add('earningsOnLeave')
            }
            if (sts.isLtd() && eventStatus.reviewed && detail.isLTDtoTerminated(true)) {
                detail.messages.add("ltdToTerminatedInfo", [
                    {
                        key: "ltdEndDate",
                        value: moment(eventStatus.endTs).format("YYYY-MM-DD"),
                    },
                ]);
            } else if (sts.isLtd() && detail.isLTDtoTerminated()) {
                detail.messages.add("ltdToTerminated");
            }
        })
        if (detail.employment.events.find(e => e.config.isDeemedCheck)) detail.messages.add("expectedDeemed");
    }

    static validateActiveInactive(detail) {
        const periodStss = detail.employmentEventStatuses
        periodStss.forEach((eventStatus, index) => {
            const sts = eventStatus.status
            if (sts.isActive()) {
                if (!detail.earnings.pensionable) return detail.messages.add('noEarnings')
                if (detail.earnings.filter(earning => earning.earningType.category.isRegular() || earning.earningType.category.isOvertime()).find(earning => earning.hours && !earning.amount)) return detail.messages.add('hoursNoEarnings')
                if (detail.earnings.filter(earning => earning.earningType.category.isRegular() || earning.earningType.category.isOvertime()).find(earning => earning.amount && !earning.hours)) return detail.messages.add('noHours')
                if ((detail.earnings.find(earning => earning.amount < 0) && !sts.isProgressiveReturn())
                    || (sts.isProgressiveReturn() && detail.earnings.deductable < 0)) detail.messages.add('negativeEarnings');
            } else if (sts.isTerminated() && !detail.employment.participation.events.find(ev => ev.code === 'adjTerm' || ev.code === 'noAdjTerm')) {
                if (detail.earnings.pensionable && !detail.cmt) detail.messages.add('earningsOnTerminated')
            }
        })
    }

    //Mid month events cause split earnings (pensionable vs non-pensionable APP-141)
    static validateMidMonth(detail) {
        let allParticipationEvents = detail.employment.participation.eventStatuses.getAllDuring(detail.period.timestampAtPeriodStart, detail.period.timestampAtPeriodEnd); 

        const hasNROLFirstDayEvent = allParticipationEvents.find(e => e.config.isFirstDayEvent);
        const midEnrEvent = detail.isMidMonthActive(allParticipationEvents);
        const hiredDtSameJoinDt = midEnrEvent?.effDt === detail.employment.hiredDate;
        if (midEnrEvent && !detail.employment.participation.events.find(ev => ev.code === 'midMonthAdj' && ev.effDt === midEnrEvent?.effDt) && !hiredDtSameJoinDt && !hasNROLFirstDayEvent) { 
            detail.messages.add('midMonthEnr', [{key: 'midMonthDt', value: moment(detail.employment.participation.joinDt).format('DD MMM YYYY')}]);
        }  
        const A60Event = detail.isMidMonthRetired(allParticipationEvents)
        if (A60Event && !detail.employment.participation.events.find(ev => ev.code === 'midMonthAdj' && ev.effDt === A60Event?.effDt)) {
            detail.messages.add('midMonthRet', [{key: 'AGE60', value: moment(detail.employment.person.sixtiethBirthday()).format('DD MMM YYYY')}]);
        }
    }


    /**
     * Calculates the contributions for the period of the remittance detail. Which includes:
     * - Regular contributions
     * - Maternity contributions
     * - LTD contributions
     * - Self contributions
     * 
     * @param {RemittanceDetail} detail 
     * @returns the remittance detail with all calculated contributions for the period
     */
    static calculateContribs(detail) {
        const voluntaryUnchanged = detail.contributions.vol;
        detail.contributions.reset();
        detail.contributions.vol = voluntaryUnchanged;
        detail.contributions.reg = detail.contributions.mat = detail.contributions.ltd = detail.contributions.slf = 0;
        if (!detail.period.yearEnd) {
            detail.earnings.remove(e => e.earningType.isDeemedEarningType());
            detail.earnings.pullFilter(e => !e.earningType.isDeemedEarningType());
        }

        // sort events
		detail.employment.eventStatuses.assignEndTs();
        detail.employment.events.sortEvents();

        // Calculate only if contributes to the plan during the period (even if is partially contributing during the period)
        let periodStss = detail.employment.eventStatuses.getAllDuringWithEndTs(
            detail.period.timestampAtPeriodStart, 
            detail.period.timestampAtPeriodEnd
        );

        periodStss = this.handleDeemedEndingAtBeginningOfPeriod(detail, periodStss);

        const isEligible = detail.ppStatus.isEligible();
        if (isEligible) {
            detail.contributions.reg = this.calculateDeduction(
                    detail.earnings.deductable, 
                    detail.ytpEarnings.pensionable, 
                    detail.ytpContributions.total, 
                    detail.isCQ === 'y', 
                    detail.rates
                );

			// Calculate deemed contributions (unless we are in year end)
			if (!detail.period.yearEnd) {
                periodStss.filter(eventStatus => !eventStatus.status.isProgressiveReturn()).forEach(eventStatus => {
                    const ytdEarnings =
                        detail.ytpEarnings.pensionable + detail.earnings.pensionable;
                    const ytdDeduction =
                        detail.ytpContributions.total +
                        detail.contributions.reg;
                    const status = eventStatus.status;
                    const isPartiallySelfContribution = detail.employment.isPartiallySelfContribution(detail.period.timestampAtPeriodStart, detail.period.timestampAtPeriodEnd);
                    const isDeemedToTerm = detail.isDeemedToFiredQuit() && !detail.isLTDtoTerminated(true);
                    const effectiveDaysInPeriod = isPartiallySelfContribution
                        ? isPartiallySelfContribution.getEffectiveDaysToEvent(
                              detail.period
                          )
                        : eventStatus.getEffectiveDaysInPeriod(
                              detail.period,
                              false
                          );
                    const statusDaysInPeriod = isDeemedToTerm
                        ? effectiveDaysInPeriod + 1
                        : effectiveDaysInPeriod;
                    const baseEarningsInPeriod = detail.employment.baseEarningsHistory.getAllDuringWithEndTs(
                        detail.period.timestampAtPeriodStart, 
                        detail.period.timestampAtPeriodEnd
                    );
                    const baseEarningsForPeriod = baseEarningsInPeriod?.[baseEarningsInPeriod.length - 1]?.value 
                        ?? detail.employment.baseEarningsHistory.getAt(eventStatus.ets)?.value;
                    const baseEarnings = baseEarningsForPeriod !== 0 ? baseEarningsForPeriod : detail.employment.baseEarnings;
                    let earnings = round(
                        ((baseEarnings * 12) /
                            detail.period.numberOfDaysInYear) *
                            statusDaysInPeriod
                    );
                    const hours = round(earnings * detail.workSchedule.monthlySchedule / baseEarnings, 2);
                    var deductions = isEligible 
                        ? this.calculateDeduction(earnings, ytdEarnings, ytdDeduction, detail.isCQ === 'y', detail.rates) 
                        : 0;
                    const isSelfContributing = detail.employment.isSelfContributingDuringPeriod(
                        detail.period.timestampAtPeriodStart, 
                        detail.period.timestampAtPeriodEnd,
                    );
                    const isEligibleForSelf = eventStatus.status.eligibleForSelfContribution();
                    
                    if (status.isMaternity()) {
                        detail.earnings.add([ new Earning({code: 'm1', amount: earnings, hours: hours, earningType: {code: 'm1'}}) ]);
                        if (status.matSts !== 'p') detail.contributions.mat += deductions; //PATCH for 2019, there is no prepaid option after 2019
                    } else if (status.isLtd()) {
                        const ageAtStartOfPeriod = moment(detail.period.date).diff(moment(detail.employment.dob), 'years');
                        if (ageAtStartOfPeriod >= 65) {
                            //TODO check if turns 65 during the period then prorate deduction
                            const ageAtEndOfPeriod = moment(detail.period.dateEndPeriod).diff(moment(detail.employment.dob), 'years');
                            deductions = 0;
                        } else {
                            const ratesAtStatusEffDate = detail.employment.employer.plan.historicRates.getRatesAtPeriod(Period.fromDate(eventStatus.effDt));
                            deductions = isEligible
                                ? this.calculateDeduction(
                                      earnings,
                                      ytdEarnings,
                                      ytdDeduction,
                                      detail.isCQ === "y",
                                      detail.rates,
                                      ratesAtStatusEffDate.ympe
                                  )
                                : 0;
                        }
                        detail.earnings.add([ 
                            new Earning({code: 'l1', amount: earnings, hours: hours, earningType: {code: 'l1'}}) 
                        ]);
                        detail.contributions.ltd += deductions;
                    } else if ((isSelfContributing && isEligibleForSelf) || isPartiallySelfContribution) {
                        detail.earnings.add([ 
                            new Earning({code: 's1', amount: earnings, hours: hours, earningType: {code: 's1'}}) 
                        ]);
                        detail.contributions.slf += deductions;
                    }
                })
            }
        }
        if (detail.containsProgressiveReturn() 
            && detail.contributions.reg > 0
            && detail.earnings.deductable > 0
            && detail.earnings.deductableHours > 0) { 
            this.calculateReducedDeemed(detail);
        }
        detail.contributions.round();
        this.calculatePensionAdjustments(detail);

        return detail;
    }

    /**
     * Handles the edge case where the fired/quit status ends at the beginning of the period (ex: 2024/11/01)
     * and there is a deemed status preceding it. In this case, the deemed status should still have one day of contributions.
     * @param {RemittanceDetail} detail remittance detail
     * @param {EmploymentEvent[]} periodStatuses original list of employment statuses in period
     * @returns list of employments statuses in period of remittance detail
     */
    static handleDeemedEndingAtBeginningOfPeriod(detail, periodStatuses) { 
        const prevPeriod = detail.period.dec();
        const empStatusAtMonthStart = detail.getEmploymentStatusAtMonthStart();
        const prevEmpStatus = detail.employment.eventStatuses.getAt(prevPeriod.timestampAtPeriodEnd);

        if (empStatusAtMonthStart.status.isFiredQuit() && prevEmpStatus.status.isDeemedStatus()) {
            prevEmpStatus.endTs = detail.period.timestampAtPeriodStart;
            periodStatuses = [prevEmpStatus, ...periodStatuses];
        }

        return periodStatuses;
    }

    static calculateReducedDeemed = (detail) => {
        const deemedContribution = detail.contributions.all.find(contrib => contrib.type.isDeemed());
        const deemedEarning = detail.earnings.all.find(earning => earning.earningType.isDeemedEarningType());
        if (deemedContribution && deemedEarning) {
            deemedContribution.amount -= detail.contributions.reg;
            deemedEarning.amount -= detail.earnings.deductable;
            deemedEarning.hours -= detail.earnings.deductableHours;

            if (deemedContribution.amount < 0) {
                deemedContribution.amount = 0;
            }
            if (deemedEarning.amount < 0) {
                deemedEarning.amount = 0;
            }
            if (deemedEarning.hours < 0) {
                deemedEarning.hours = 0;
            }
    
            detail.messages.add('reducedDeemed');
        }
    }

    static calculateDeduction(earnings, ytdEarnings, ytdContributions, isCQPPContributor, rates, ympeOverride) {
        if (ytdContributions >= rates.maxContributions) return 0
        const ympe = ympeOverride || rates.ympe
        var deduction = earnings * rates.deductionRate
        if ( !isCQPPContributor || ytdEarnings > ympe) { //if not CPP/QPP or YMPE was already reach then use incressed rate
            deduction = earnings * rates.increassedRate
        } else if ( ytdEarnings + earnings > ympe ) { //if we reached the YMPE this month then use the increase rate for the surplus 
            deduction += (ytdEarnings + earnings - ympe) * (rates.increassedRate - rates.deductionRate)
        }
        
        if (ytdContributions + deduction > rates.maxContributions) {
            deduction = rates.maxContributions - ytdContributions
        }
        return deduction
    }

    static validateEarning(detail){
        const adjEvent = detail.employment.participation.events.find(ev => ev.code === 'adjTerm' || ev.code === 'noAdjTerm');
        const nrolEvent = detail.employment.participation.events.find(ev => ev.config.isEnrollEvent);
        const penEmpEvent = detail.employment.participation.events.find(ev => ev.code === 'penEmp');

        const ppStatus = detail.ppStatus;
        //Earnings total of zero, but pensionable and no pensionable has the same amount
        const hasEarnings = detail.earnings.total !== 0 && detail.earnings.pensionable > 0;
        const samePenAndNoPen = !hasEarnings && Math.abs(detail.earnings.pensionable) === Math.abs(detail.earnings.nonPensionable);
        const hasFinancialData = hasEarnings || detail.earnings.hours !== 0 || (detail.earnings.pensionable !== 0 && samePenAndNoPen)
        if(!adjEvent){
           if(ppStatus.isClose() && hasFinancialData){
                detail.messages.add('closeEarningAdj');
            }
        }
        if(ppStatus.isEligiblePeriod() && hasFinancialData && !nrolEvent){
            detail.messages.add('eligEarning');
        }
        if(penEmpEvent && !hasEarnings) {
            // see also RemittanceDetail.isRelevant()
            detail.messages.add('noEarningsAt60');
        }
    }

    static calculatePensionAdjustments(detail) {
        const mpl = detail.rates.moneyPurchaseLimit //TODO find last one for the year (needed??)
        const ympe = detail.rates.ympe
        const tde = detail.ytdEarnings.pensionable
        const svc = detail.ytdCreditedService
        
        const calc3 = 9 * detail.isCQ !== 'y' ? 0.02 * tde : (0.02 * tde * 12 / svc - 0.005 * Math.min(ympe, tde * 12 / svc)) * svc / 12
        return round(Math.min(mpl, mpl * svc / 12, calc3) - 600 * svc / 12)
    }
}
